/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// 共通CSS
import "./src/styles/common.scss"
import "./src/styles/foundation/variable.scss"
import "lazysizes"

import firebase from "firebase/app"
import "firebase/functions"

// firebaseの初期化
const contactServerConfig = {
  apiKey: "AIzaSyDlX_sPqQJB8LpBZ4NVfcRsrr65y_WtW3c",
  authDomain: "oh-tam-lp-contact.firebaseapp.com",
  projectId: "oh-tam-lp-contact",
  storageBucket: "oh-tam-lp-contact.appspot.com",
  messagingSenderId: "338332911281",
  appId: "1:338332911281:web:a95d631a63bf17f6dd0490",
  measurementId: "G-9P050NYR2Q",
}
// IntersectionObserver polyfill (Safari, IE)
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
  if (typeof window !== `undefined`) {
    firebase.initializeApp(contactServerConfig, "contactServer")
  }
}
